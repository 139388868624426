import * as React from "react"
import { ChangeEvent, FormEvent } from "react"
import { navigate } from "gatsby"

type Props = {
  url: string
}

type State = {
  email: string
  name: string
  message: string
}

export class Contact extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      email: "",
      name: "",
      message: "",
    }
  }

  render() {
    return (
      <div className="forms-container forms-container-full">
        <form id="forms-form" className="forms-validate forms-form" onSubmit={this.handleForm}>
          <div className="forms-field-container">
            <div id="forms-field_0-container" className="forms-field forms-field-name" data-field-id="0">
              <label className="forms-field-label">
                Your Name <span className="forms-required-label">*</span>
              </label>
              <input
                type="text"
                onChange={this.onNameChange}
                value={this.state.name}
                id="forms-field_0"
                className="forms-field-large forms-field-required"
                name="name"
                required
              />
            </div>
            <div id="forms-field_1-container" className="forms-field forms-field-email" data-field-id="1">
              <label className="forms-field-label">
                Your Email <span className="forms-required-label">*</span>
              </label>
              <input
                type="email"
                onChange={this.onEmailChange}
                value={this.state.email}
                id="forms-field_1"
                className="forms-field-large forms-field-required"
                name="email"
                required
              />
            </div>
            <div id="forms-field_2-container" className="forms-field forms-field-textarea" data-field-id="2">
              <label className="forms-field-label">
                Your Message <span className="forms-required-label">*</span>
              </label>
              <textarea
                onChange={this.onMessageChange}
                value={this.state.message}
                id="forms-field_2"
                className="forms-field-medium forms-field-required"
                name="message"
                required
              />
            </div>
          </div>
          <div className="forms-submit-container">
            <button
              type="submit"
              name="submit"
              className="forms-submit "
              id="forms-submit"
              value="forms-submit"
              data-alt-text="Sending..."
            >
              Send Now!
            </button>
          </div>
        </form>
      </div>
    )
  }

  private handleForm = (e: FormEvent) => {
    e.preventDefault()

    if (this.state.email.trim() && this.state.name.trim() && this.state.message.trim()) {
      require("es6-promise").polyfill()
      require("isomorphic-fetch")

      fetch(process.env.GATSBY_API_URL + "contact-form/" + process.env.GATSBY_API_TOKEN, {
        method: "POST",
        body: JSON.stringify({ email: this.state.email, name: this.state.name, message: this.state.message }),
        headers: { "Content-Type": "application/json" },
      })
        .then(res => res.json())
        .then(response => console.log("Success:", JSON.stringify(response)))
        .catch(error => console.error("Error:", error))
    }
    navigate(`/${this.props.url}/`)
  }

  private onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value })
  }

  private onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: e.target.value })
  }

  private onMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ message: e.target.value })
  }
}
