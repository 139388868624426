import * as React from "react"

import { Contact } from "../components/Contact"
import { SEO } from "../components/seo"
import { PrimaryHeader } from "../components/header"
import { Footer } from "../components/Footer"
import { clearSnappy } from "../model/helpers"

const top_bg = require("../images/about-us/top_bg.jpg")
const contact_bg = require("../images/about-us/contact_bg.jpg")

const ContactUs = () => (
  <>
    {clearSnappy()}
    <PrimaryHeader slug="contact-us" />
    <SEO isQuiz={true} title="Contact Us" url="contact-us" />
    <div className="container-fluid">
      <div
        className="row parallax top-bg pad-top-250 pad-bottom-250"
        style={{ backgroundImage: "url(" + top_bg + ")" }}
      >
        <div className="col-xs-12">
          <div className="container align-center">
            <div className="row pad-top-20">
              <div className="col-xs-12 page-subheadline orange">GOT ISSUES?</div>
            </div>
            <div className="row">
              <div className="col-xs-12 page-headline">CONTACT US</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row white-bg pad-bottom-80 pad-top-80">
        <div className="col-xs-12">
          <div className="container align-center">
            <div className="col-sm-4">
              <div className="row pad-bottom-20">
                <div className="col-md-12 section-subsubheadline grey">SITE INFORMATION</div>
              </div>
              <div className="row pad-bottom-40">
                <div className="col-md-12 align-left">
                  We work hard to ensure that all questions and answers on our site are accurate. If you notice a typo
                  or any incorrect information, please contact us below to report any inaccurate or incorrect
                  information.
                </div>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="row pad-bottom-20">
                <div className="col-md-12 section-subsubheadline grey">ISSUES WITH ADS</div>
              </div>
              <div className="row pad-bottom-40">
                <div className="col-md-12 align-left">
                  We strive to offer tasteful ads that are relevant to our readership and their values. We have a strict
                  policy against advertisements that force users to another site against their wishes. We aggresibely
                  prohibit any adsvertisers that violate this policy. If you have a problem with any of the advertising
                  you've seen on TriviaBoss, please contact us below. We will do our best to make your experience
                  better!
                </div>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="row pad-bottom-20">
                <div className="col-md-12 section-subsubheadline grey">ADVERTISE WITH US</div>
              </div>
              <div className="row pad-bottom-40">
                <div className="col-md-12 align-left">
                  Trivia is a great way to find new customers, or educate your existing ones! Reach out to speak with a
                  representative from Trivia Boss about advertising opportunities that work for your brand!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <span className="anchor-link" id="contact" />
      <div
        className="row parallax contact-bg pad-top-80 pad-bottom-80"
        style={{ minHeight: "500px", backgroundImage: "url(" + contact_bg + ")" }}
      >
        <div className="col-md-12">
          <div className="container align-center">
            <div className="row">
              <div className="col-xs-12 section-subheadline orange">NEED A SHOULD TO CRY ON?</div>
            </div>

            <div className="row pad-bottom-40">
              <div className="col-xs-12 section-headline white">CONTACT US</div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <div className="row">
                  <div className="col-xs-2">
                    <i className="fas fa-mobile-alt small-about-icon orange" />
                  </div>
                  <div className="col-xs-10 align-left white larger-body-font bold">Phone:</div>
                </div>
                <div className="row">
                  <div className="col-xs-10 col-xs-offset-2 align-left white">(404) 618-0198</div>
                </div>

                <div className="row pad-top-20">
                  <div className="col-xs-2">
                    <i className="fas fa-map-marker-alt small-about-icon orange" />
                  </div>
                  <div className="col-xs-10 align-left white larger-body-font bold">Address:</div>
                </div>
                <div className="row">
                  <div className="col-xs-10 col-xs-offset-2 align-left white">
                    1372 Peachtree St. NE
                    <br />
                    Atlanta, Ga. 30309
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12 align-left" style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                    <div className="about-map" />
                  </div>
                </div>
              </div>

              <div className="col-sm-9 section-subheadline orange">
                <Contact url="contact-us" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
)

export default ContactUs
